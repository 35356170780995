import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { patentRegister, patentUpdate} from '../../../api/List';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingStateSelect } from '../../../atom/state';

const BannerDetail = (props) => {

    const [ getPatentContent, setPatentContent ] = useState('');
    const [ getPatentTitle, setPatentTitle ] = useState('');
    const [ getImageFile, setImageFile ] = useState(null);
    const [ _loading, setLoading ] = useRecoilState(loadingStateSelect);
    const [ oldImageFile, setOldImageFile ] = useState('');

    const filesUploadHandle = (e) => { 
        setImageFile(e.target.files[0]); 
        // console.log(e);
        // console.log(getImageFile);
        if(oldImageFile == "") {
            setOldImageFile(e.target.value);
        }
    }
    const patentContentHandle    = (e) => { setPatentContent(e.target.value); }
    const patentTitleHandle = (e) => { setPatentTitle(e.target.value); }

    useEffect(() => {
        if(props.data){
            setPatentContent(props.data.content);
            setPatentTitle(props.data.title);
            setOldImageFile(props.data.file_addr);
        }
    }, []);

    const registerHandle = (e) => {

        if ( !getPatentTitle || !getPatentContent ) { alert("내용 및 이미지를 모두 입력해주세요."); return; }

        if ( !oldImageFile ) { if ( !getImageFile ) {  alert("이미지를 입력해주세요."); return; } }

        if ( getPatentTitle == props.data.title ) {
            if ( getPatentContent == props.data.content ) {
                if ( oldImageFile ) {
                    if( !getImageFile ) {
                        window.location.href = "/list";
                        return;
                    }
                }
            }
        }

        if ( oldImageFile ) { setImageFile('');
        } else {
            if ( !getImageFile ) { setImageFile(null); }
        }  

        if(e.target.getAttribute("type") == "register") {

            let data = {title : getPatentTitle, content : getPatentContent, image : getImageFile}
            let check = patentRegister('register/patent', data);
            check.then((res) => {
                if(res.data.success) {
                    window.location.href = "/list";
                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                }
            });

        } else {
            
            let data = {id: props.data.id, title : getPatentTitle, content : getPatentContent, image : getImageFile}
            let check = patentUpdate('update/patent', data);
            check.then((res) => {
                if(res.data.success) {
                    window.location.href = "/list";
                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                }
            });

        }
    }

    let filename = "";

    if(props.data.file_addr) {
        let imageUrl = props.data.file_addr;
        let urlStrSplit = imageUrl.split("/");
        filename = urlStrSplit[urlStrSplit.length-1];
    }

    const imageDeleteHandle = () => { setOldImageFile(""); }
    const resetPopupLinkDataHandle = (e) => { if(!getPatentContent) { e.target.value = ""; setPatentContent(''); } }
    const resetPopupTitleDataHandle = (e) => { if(!getPatentTitle) { e.target.value = ""; setPatentTitle(''); } }
    useEffect(() => { setOldImageFile(filename); }, [])


    return (
        <Container>
            <Content>
                <Header>
                    <h2>메인팝업 관리</h2>
                </Header>
                <ContentBox>
                    <ContentHeader>
                        <h2>메인팝업 { oldImageFile ? "수정" : "등록" }</h2>
                    </ContentHeader>
                    <ContentTable>
                        <RowTable>

                            <Row>
                                <LabelSection>특허 번호</LabelSection>
                                <TextArea 
                                    placeholder='특허 번호를 입력하세요.'
                                    onChange={patentTitleHandle}
                                    onClick={resetPopupTitleDataHandle}
                                    value={getPatentTitle}
                                />
                            </Row>

                            <Row>
                                <LabelSection>특허 제목</LabelSection>
                                <TextArea 
                                    placeholder='특허 제목을 입력하세요.'
                                    onChange={patentContentHandle}
                                    onClick={resetPopupLinkDataHandle}
                                    value={getPatentContent}
                                />
                            </Row>

                            <Row>
                                <LabelSection>이미지</LabelSection>
                                { oldImageFile ?
                                    <FileSection>
                                        <OldImageFile onClick={imageDeleteHandle}>
                                            {oldImageFile} X
                                        </OldImageFile> 
                                    </FileSection>
                                        :
                                    <FileSection>
                                        <LabelStyle htmlFor='popUpFile'> * 오른쪽 파일찾기 버튼을 눌러 이미지를 등록해 주세요)
                                            <UploadFileButton><span>파일찾기</span></UploadFileButton>
                                        </LabelStyle>
                                        <InputFile 
                                            type="file"
                                            id="popUpFile"
                                            accept='image/jpg, impage/png, image/jpeg, image/webp'
                                            onChange={filesUploadHandle}
                                        /> 
                                    </FileSection>
                                }
                            </Row>
                        </RowTable>

                        

                    </ContentTable>
                    <ContentButton>
                    <ContentButtonCenter>
                        <LinkStyle to="/list">목록</LinkStyle>

                        <ControllerButton 
                            status="register"
                            type={props.data.title ? "update" : "register"}
                            onClick={registerHandle}
                        >
                            <span>{props.data ? "저장" : "등록"}</span>
                        </ControllerButton>
                    </ContentButtonCenter>
                    </ContentButton>
                </ContentBox>
            </Content>
        </Container>
    )
}

export default BannerDetail;

const Container = styled.div`
    width: calc(100% - 200px);
    min-width: 1500px;
    height: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
`;

const Content = styled.div`
    width: calc(100% - 100px);
    min-height: 540px;
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    
`;

const ContentBox = styled.div`
    width: 100%;
    min-width: 720px;
    height: 420px;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
`;

// ContentBox > ContentHeader
const ContentHeader = styled.div`
    width: 100%;
    height: 60px;
    padding: 20px;
    h2 {
        color: #ff9900;
        font-weight: bold;
    }
`;

// ContentBox > ContentTable
const ContentTable = styled.div`
    width: 100%;
    height: 250px;  
    display: flex;
    flex-direction: row;
    padding: 30px;
`;

const RowTable = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #d5d5d5;
    border-top: 3px solid #444;
`;

const Row = styled.div`
    width: 100%;
    height: calc(100% / 3);
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #d5d5d5; 
`;

const TopTitle = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
`;

const BottomTitle = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
`;

const ImageSelect = styled.div`
    widht: 100%;
    height: 30%;
    border: 1px solid #d5d5d5;
    border-top: none;
    display:flex;
`;

const ContentButton = styled.div`
    width: 100%;
    height: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
`;

const ContentButtonCenter = styled.div`
    display: flex;
`;

const LabelSection = styled.div`
    width: 200px;
    height: 100%;
    background-color: #f5f5f5;
    padding: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    border-right: 1px solid #d5d5d5;
`;

const TextArea = styled.textarea`
    width: calc(100% - 200px);
    height: 100%;
    border: none;
    resize: none;
    outline: none;
    padding: 20px;
`;

const FileSection = styled.div`
    width: calc(100% - 200px);
    display:flex;
    align-items: center;
    padding-left: 20px;
`;

const InputFile = styled.input`
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
`;

const ControllerButton = styled.div`
    display:flex;
    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
    }

    justify-content: center;
    align-items: center;

`;

const LinkStyle = styled(Link)`
    display:flex;
    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
        color:#fff;
    }

    justify-content: center;
    align-items: center;
`

const OldImageFile = styled.div`
    width: 100%
    height: 100%;
    padding: 5px;
    background-color: #f5f5f5;
    font-weight: bold;
`;

const LabelStyle = styled.label`
    font-size: 12px;
    font-weight: 500;
    color: #8d8d8d;
    margin-right: 200px;
    width: calc(100%-120px);
    &:hover {
        cursor: pointer;
    }
`;

const UploadFileButton = styled.div`
    width: 120px;
    height: 40px;
    border: solid 1px #f90;
    font-size: 1rem;
    font-weight: bold;
    color: #f90;
    text-align: center;
    margin: 20px;
    padding-top: 7px; 
    display: inline-block;
`;