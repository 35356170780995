import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Checkbox } from 'antd';

import { noticeRegister, noticeUpdate} from '../../../api/List';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingStateSelect } from '../../../atom/state';
import { ko } from "date-fns/esm/locale";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from './components/Loading';
import { detail } from '../../../api/List';
import DragDrop from './components/DragDrop';


const NoticeDetail = (props) => {

    const [ getNewsTopCheck, setNewsTopCheck ] = useState(false);
    const [ getNewsTitle, setNewsTitle ] = useState('');
    const [ getContent, setContent ] = useState('');
    const [ getFiles, setFiles ] = useState([]);
    const [ getRemoveFiles, setRemoveFiles ] = useState(new Set());

    const [ tableData, setData ] = useState([]);
    const [ tableFile, setTableFiles ] = useState([]);

    const [ _loading, setLoading ] = useState(true);
    const [ oldFiles, setOldFiles ] = useState('');

    const filesUploadHandle = (e) => { 
        setFiles(e.target.files[0]);
        setOldFiles(e.target.files[0].name);
    }

    useEffect(() => {
        setLoading(true);
        if(props.data) {
            if(tableData){
                if(tableData.length < 1) {
                    let check = detail('detail/notices', props.data.id);
                    check.then((res) => { 

                        setData(res.data.data);

                        if(res.data.data.notices != null) {
                            if ( Object.keys(res.data.data.notices).length > 0 ) {
                                setNewsTopCheck(res.data.data.notices.status == 3 ? true : false);
                                setNewsTitle(res.data.data.notices.title);
                                setContent(res.data.data.notices.content);
                            }
                        }

                        if(res.data.data.files != null) {
                            if ( res.data.data.files != null || Object.keys(res.data.data.files).length > 0 ) {
                                setFiles(res.data.data.files);
                                setOldFiles(res.data.data.files.file_name);
                                // console.log('file', oldFiles);
                            }
                        }
                    });  
                }
            }
        }
        setTimeout(()=>{ setLoading(false); }, 500);
    }, []); 

    const registerHandle = (e) => {

        if(e.target.getAttribute("type") == "register") {
            
            if(!getNewsTitle || !getContent) {
                alert("제목 혹은 상세 내용은 필수 값입니다.");
                return;
            }

            let data = {check: getNewsTopCheck, title : getNewsTitle, content : getContent, file : getFiles}        
            let check = noticeRegister('register/notices', data);
            check.then((res) => {
                if(res.data.success) {
                    window.location.href = "/list";
                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                }
            });

        } else {

            if(!getNewsTitle || !getContent) {
                alert("제목 혹은 상세 내용은 필수 값입니다.");
                return;
            }

            if(!getFiles) {
                if (window.confirm('파일 없이 저장하실 경우, 기존 파일은 삭제됩니다.')) {

                } else {
                    return;
                }
            }
            
            let data = {id: props.data.id, check: getNewsTopCheck, title : getNewsTitle, content : getContent, file : getFiles}
            let check = noticeUpdate('update/notices', data);
            check.then((res) => {
                if(res.data.success) {
                    window.location.href = "/list";
                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                }
            });

        }
    }

    const newsTopCheck = (e) => { setNewsTopCheck(e.target.checked); };
    const newsTitleHandle = (e) => { setNewsTitle(e.target.value); };

    const modules = {
        toolbar: [
          //[{ 'font': [] }],
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          [{ 'align': [] }, { 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          ['clean'],
          [{'handlers' : {'image': 'imageHandler' } }],
        ],
      }
    
    const formats = [
        'font',
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
        'align', 'color', 'background',        
    ];

    // const imageHandler = () => {
    //     const input = document.createElement("input");
    //     input.setAttribute("type", "file");
    //     input.setAttribute("accept", "image/*");
    //     input.click();

    //     input.onchange = async () => {
    //         if(input.files) {
    //             var file = input.files[0];
    //             var formData = new FormData();

    //             formData.append("image", file);
    //             var filename = file.name;
    //         }
    //     }
    // }

    const handleText = (text) => {
        setContent(text);
    }

    const imageDeleteHandle = () => {
        setFiles(null);
        setOldFiles('');
    }

    // const imageDeleteHandle = (fileId) => {
    //     let temp_files = [];

    //     getFiles.map((file) => {
    //         if(file.id != fileId) {
    //             temp_files.push(file);
    //         } else {
    //             getRemoveFiles.add(file.id);
    //         }
    //     })
    //     setFiles(temp_files);
    // }
    
    return (
        <Container>
            <Content>
                <Header>
                    <h2>공지사항 관리</h2>
                </Header>
                <ContentBox>
                    <ContentHeader>
                        <h2>공지사항 { props.data ? "수정" : "등록" }</h2>
                    </ContentHeader>
                    <ContentTable>
                        <RowTable>

                            <Row>
                                <LabelSection>상단고정여부</LabelSection>
                                <CheckboxStyle
                                    onChange={newsTopCheck}
                                    checked={getNewsTopCheck}
                                />
                            </Row>

                            <Row>
                                <LabelSection>제목</LabelSection>
                                <InputText 
                                    placeholder='제목을 입력하세요.'
                                    onChange={newsTitleHandle}
                                    defaultValue={getNewsTitle}
                                />
                            </Row>

                            <Row>
                                <LabelSection>내용</LabelSection>
                                <EditorBox>
                                <ReactQuillStyle 
                                    style={{height: "600px"}} 
                                    theme="snow" 
                                    modules={modules} 
                                    formats={formats} 
                                    value={getContent}
                                    onChange={handleText} />
                                </EditorBox>
                            </Row>

                            <Row>
                                <LabelSection>첨부파일</LabelSection>
                                { oldFiles ?
                                    <FileSection>
                                        <OldImageFile onClick={imageDeleteHandle}>
                                            {oldFiles} X
                                        </OldImageFile> 
                                    </FileSection>
                                        :
                                    <FileSection>
                                        <LabelStyle htmlFor='newsFile'> * 오른쪽 파일찾기 버튼을 눌러 이미지를 등록해 주세요)
                                            <UploadFileButton><span>파일찾기</span></UploadFileButton>
                                        </LabelStyle>
                                        <InputFile 
                                            type="file"
                                            id="newsFile"
                                            onChange={filesUploadHandle}
                                        /> 
                                    </FileSection>
                                }
                            </Row>
                        </RowTable>

                    </ContentTable>
                    <ContentButton>
                    <ContentButtonCenter>
                        <LinkStyle to="/list">목록</LinkStyle>

                        <ControllerButton 
                            status="register"
                            type={props.data.id ? "update" : "register" }
                            onClick={(e) => registerHandle(e)}
                        >
                            <span>{props.data.id ? "저장" : "등록"}</span>
                        </ControllerButton>
                    </ContentButtonCenter>
                    </ContentButton>
                </ContentBox>
            </Content>
        </Container>
    )
}

export default NoticeDetail;

const Container = styled.div`
    width: calc(100% - 200px);
    min-width: 768px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
`;

const Content = styled.div`
    width: calc(100% - 100px);
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    
`;

const ContentBox = styled.div`
    width: 100%;
    min-width: 720px;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
`;

// ContentBox > ContentHeader
const ContentHeader = styled.div`
    width: 100%;
    height: 60px;
    padding: 20px;
    h2 {
        color: #ff9900;
        font-weight: bold;
    }
`;

// ContentBox > ContentTable
const ContentTable = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: row;
    padding: 30px;
`;

const RowTable = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: column;
    border: 1px solid #d5d5d5;
    border-top: 3px solid #444;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #d5d5d5; 
`;

const TopTitle = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
`;

const BottomTitle = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
`;

const ImageSelect = styled.div`
    widht: 100%;
    height: 30%;
    border: 1px solid #d5d5d5;
    border-top: none;
    display:flex;
`;

const ContentButton = styled.div`
    width: 100%;
    height: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
`;

const ContentButtonCenter = styled.div`
    display: flex;
`;

const LabelSection = styled.div`
    width: 200px;
    height: 100%;
    background-color: #f5f5f5;
    padding: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    border-right: 1px solid #d5d5d5;
`;

const TextArea = styled.textarea`
    width: calc(100% - 200px);
    height: 100%;
    border: none;
    resize: none;
    outline: none;
    padding: 20px;
`;

const FileSection = styled.div`
    width: calc(100% - 200px);
    display:flex;
    padding-left:20px;
    align-items: center;
`;

const InputFile = styled.input`
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
`;

const InputText = styled.input`
    width: calc(100% - 200px);
    padding: 10px;
    border: 0;
    outline: none;
    padding-left: 20px;
`;

const ControllerButton = styled.div`
    display:flex;
    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
    }

    justify-content: center;
    align-items: center;

`;

const LinkStyle = styled(Link)`
    display:flex;
    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
        color:#fff;
    }

    justify-content: center;
    align-items: center;
`

const OldImageFile = styled.div`
    width: 100%
    height: 56px;
    padding: 5px;
    background-color: #f5f5f5;
    font-weight: bold;
`;

const LabelStyle = styled.label`
    font-size: 12px;
    font-weight: 500;
    color: #8d8d8d
    width: calc(100%-120px);
    &:hover {
        cursor: pointer;
    }
`;

const UploadFileButton = styled.div`
    width: 120px;
    height: 40px;
    border: solid 1px #f90;
    font-size: 1rem;
    font-weight: bold;
    color: #f90;
    text-align: center;
    margin: 20px;
    padding-top: 7px; 
    display: inline-block;
`;


const EditorBox = styled.div`
    width: calc(100% - 200px);
    height: 650px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 70px;
`;

const ReactQuillStyle = styled(ReactQuill)`
    width: 100%;
    height: 100%;
    margin: 0;
`;

const CheckboxStyle = styled(Checkbox)`
    position: relative;
    left: 20px;
    top: 20px;
`;

const DragBox = styled.div`
    width: calc(100% - 200px);
    height: 300px;
    padding: 20px;
    overflow: scroll;
`;