import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { Input, Button, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

import LoginBgImage from '../images/admin_login_bg.png';

import {loginCheck} from '../api/Login';

const Login = () => {

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const login = () => {

    let check = loginCheck('login', { username: username, password: password});
    check.then((res) => { 
      if(res) {
        window.location.href = '/list';
      }
    });
  };

  return(
    <Container url={LoginBgImage}>
        <ItemBox>
          <Logo>
            <Text>
              Plabiologics
            </Text>
          </Logo>
          <InputLogin 
            size="large" 
            placeholder="Account" 
            prefix={<UserOutlined />} 
            onChange={e => setUserName(e.target.value)}
          />
          <InputLogin 
            size="large" 
            type="password" 
            placeholder="Password" 
            prefix={<UserOutlined />} 
            onChange={e => setPassword(e.target.value)}
          />
          <LoginButton type="primary"
            onClick={login}
          >
            Login
          </LoginButton>
        </ItemBox>
    </Container>
  )
}

export default Login;

const LoginButton = styled(Button)`
  width: 500px;
  height: 40px;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  background-color: #1e2834;
`

const InputLogin = styled(Input)` 
  width: 500px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 10px;
`

const Text = styled(Typography)`
  font-size: 55px;
  font-weight: bold;
  color: white;
`

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${(props)=> props.url});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Logo = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;

const ItemBox = styled.div`
  width: 50%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;