import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import icon_file_remove from '../../../../images/icon_file_list_remove.png';


const DragDrop = (props) => {

    // ref
    const inputRef = React.useRef(null);

    // drag state
    const [dragActive, setDragActive] = React.useState(false);

    // handle drag events
    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            alert(e.dataTransfer.files);
        // at least one file has been dropped so do something
        // handleFiles(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
        // at least one file has been selected so do something
        alert(e.target.files);
        // handleFiles(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    //
    return (
        <Container>
            <div id="upload-file-list-box">
                <div id="upload-file-list">
                    <span>파일명</span>
                    <img src={icon_file_remove} />
                </div>
            </div>

            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                <label id="label-file-upload" htmlFor='input-file-upload' multiple={true} className={dragActive ? "drag-active" : "" }>
                    <div id="placeholder-file">
                        <p>파일을 마우스로 끌어 넣어주시거나 <span>파일첨부</span> 해주세요.</p>
                    </div>
                    <button className="upload-button" onClick={onButtonClick}>파일첨부</button>
                </label>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>
        </Container>
    )
}

export default DragDrop;

const Container = styled.div`

    width: 100%;
    height: 100%;
    border: solid 0.6px #aaa;
    position: relative;
    overflow: auto;

    #upload-file-list-box {
        // display: none;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: calc(100% -56px);
        overflow-y: scroll;

        #upload-file-list {        
            width: 100%;
            height: 56px;
            background-color: #f5f5f5;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 30px;

            img {
                margin-left: 10px;
                width: 16px;
                height: 16px;
            }
        }
    }

    form {
        display: none;
        height: 20rem;
        width: 100%;
        height: 100%;
        max-width: 100%;
        text-align: center;
        position: absolute;
        top:0;
        left:0;

        input {
            display: none;
        }
        
        label {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: #f5f5f5;
            overflow: hidden;
            position: relative;

            .drag-active {
                background-color: #ffffff;
            }

            #placeholder-file {
                display:flex;
                width:100%;
                flex-direction: column;
                height: calc(100% - 56px);
                justify-content: center;
                border-bottom: solid 0.6px #aaa;

                p {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 800;
                    color: #bbb;

                    span {
                        color: #ff9900;
                        font-weight: bold;
                    }
                }
            }
            .upload-button {
                width: 100%;
                height: 56px;
                cursor: pointer;
                font-size: 1rem;
                border: none;
                background-color: #ffffff;
            }
              
            .upload-button:hover {
                text-decoration-line: underline;
            }
        }
    }

    #drag-file-element {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
      }
`;