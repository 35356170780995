import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { detail } from '../../../api/List';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingStateSelect } from '../../../atom/state';
import { articelsRegister, articelsUpdate} from '../../../api/List';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";

import Loader from './components/Loading';

const ArticelDetail = (props) => {

    const [ pageStatus,     setPageStatus ]  = useState(false);             // 등록/수정 상태 [ false: 등록, ]
    const [ _loading,       setLoading ]     = useState(true);              // 페이지 로딩 상태
    const [ tableData,      setData ]        = useState([]);                // 

    // getTitle / getShortTitle / getWriterInfo / getWriter / getLink / getSelectDate / getContent

    const [ getTitle,       setTitle ]       = useState('');                // 논문제목
    const [ getShortTitle,  setShortTitle ]  = useState('');                // 한줄요약
    const [ getWriterInfo,  setWriterInfo ]  = useState('');                // 발행정보
    const [ getWriter,      setWriter ]      = useState('');                // 저자

    const [ getLink,        setLink ]        = useState('');                // 논문링크
    const [ getSelectDate,  setSelectDate ]  = useState(new Date());        // 발행날짜
    const [ getContent,     setContent ]     = useState('');                // 논문내용
    
    function dateIsValid(date) { return !Number.isNaN(new Date(date).getTime()); }

    useEffect(() => {
        if(props.data.length > 0) { setPageStatus(true); } 
        else if (Object.keys(props.data).length > 0) {
            setPageStatus(true);
        }

        if(props.data) {
            if(tableData){
                if(tableData.length < 1) {
                    let check = detail('detail/articels', props.data.id);
                    check.then((res) => { 
                        setData(res.data.data.articels);
                    });
                }
                setTitle(tableData.title || '');
                setShortTitle(tableData.short_title || '');
                setWriterInfo(tableData.writer_info || '');
                setWriter(tableData.writer || '');
                setLink(tableData.link || '');
                setContent(tableData.content || '');
                
                if(tableData.register_date) {

                    let initDate = tableData.register_date;

                    if(!dateIsValid(initDate)) {
                        
                        const convertDate = new Date(Number(tableData.register_date));
                        
                        if(dateIsValid(convertDate)) {
                            initDate = convertDate;
                        }
                    }

                    setSelectDate(new Date(initDate));
                }
                
                setTimeout(()=>{ setLoading(false); }, 500);

            }
        }
        
    }, [tableData]);

    let loadingComponent; 
    if(_loading) { loadingComponent = <Loader type="spin" color="#f90" />; }

    function leftPad(value) {
        if(value >= 10) { return value; }
        return `0${value}`;
    }

    const setSelectEndDateHandle = (date) => {
        const year = date.getFullYear();
        const month = leftPad(date.getMonth() + 1);
        const day = leftPad(date.getDate());
        const toTimestamp = new Date([year, month, day].join('-'));
        setSelectDate(new Date(toTimestamp).getTime());
    }

    const registerHandle = (e) => {

        // 수정된 사항이 없는 경우 리스트 화면으로 이동

        if(e.target.getAttribute("type") == "register") {

            let data = { title: getTitle, short_title: getShortTitle, writer_info: getWriterInfo, writer: getWriter, link: getLink, register_date: getSelectDate, content: getContent}
            let check = articelsRegister('register/articels', data);
            check.then((res) => {
                if(res.data.success) {
                    window.location.href = "/list";
                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                }
            });

        } else {

            let data = { 
                id: props.data.id, 
                title: getTitle || '', 
                short_title: getShortTitle || '', 
                writer_info: getWriterInfo || '', 
                writer: getWriter || '', 
                link: getLink || '', 
                register_date: getSelectDate, 
                content: getContent || ''
            }

            let check = articelsUpdate('update/articels', data);
            check.then((res) => {
                if(res.data.success) {
                    alert("수정되었습니다.");
                    window.location.href = "/list";
                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                }
            });
        }
    }

    const setTitleHandle        = (e) => { setTitle(e.target.value || '') };
    const setShortTitleHandle   = (e) => { setShortTitle(e.target.value || '');};
    const setWriterInfoHandle   = (e) => { setWriterInfo(e.target.value || ''); };
    const setWriterHandle       = (e) => { setWriter(e.target.value || ''); };
    const setLinkHandle         = (e) => { setLink(e.target.value || ''); };
    const setContentHandle      = (e) => { setContent(e.target.value || ''); };

    useEffect(() => {

    }, [
        getTitle, 
        getShortTitle, 
        getWriterInfo, 
        getWriter, 
        getLink, 
        getSelectDate, 
        getContent
    ]);

    
    //console.log('data', data);

    return (
        <Container>
            {loadingComponent}
            <Content>
                <Header>
                    <h2>논문현황 관리</h2>
                </Header>
                <ContentBox>
                    <ContentHeader>
                        <h2>논문현황 { pageStatus ? "수정" : "등록" }</h2>
                    </ContentHeader>
                    <ContentTable>
                        <RowTable>
                                <Column>
                                    <LabelSection>논문 제목</LabelSection>
                                    <InputText 
                                        placeholder='논문의 제목을 입력하세요.'
                                        defaultValue={getTitle}
                                        onKeyUp={(e) => setTitleHandle(e)}
                                    >
                                    </InputText>
                                </Column>
                                <Column>
                                    <LabelSection>한줄 요약</LabelSection>
                                    <InputText 
                                        placeholder='내용을 입력하세요.'
                                        defaultValue={getShortTitle}
                                        onKeyUp={(e) => setShortTitleHandle(e)}
                                    >
                                    </InputText>
                                </Column>
                                <Column>
                                    <LabelSection>발행 정보</LabelSection>
                                    <InputText 
                                        placeholder='내용을 입력하세요.'
                                        defaultValue={getWriterInfo}
                                        onKeyUp={(e) => setWriterInfoHandle(e)}
                                    >
                                    </InputText>
                                </Column>
                                <Column>
                                    <LabelSection>저자</LabelSection>
                                    <InputText 
                                        placeholder='내용을 입력하세요.'
                                        defaultValue={getWriter}
                                        onKeyUp={(e) => setWriterHandle(e)}
                                    >
                                    </InputText>
                                </Column>
                                <Column>
                                    <Row>
                                        <LabelSection>논문 링크</LabelSection>
                                        <InputText 
                                        placeholder='https://'
                                        defaultValue={getLink}
                                        onKeyUp={(e) => setLinkHandle(e)}
                                    >
                                    </InputText>
                                    </Row>
                                    <Row>
                                        <LabelSection>발행날짜</LabelSection>
                                        <DatePickerReStyle
                                            locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                            selected={getSelectDate}
                                            placeholderText="발행날짜를 선택하세요."
                                            closeOnScroll={true}
                                            onChange={(date) => setSelectEndDateHandle(date)}
                                        >    
                                        </DatePickerReStyle>
                                    </Row>
                                </Column>
                                <Column>
                                    <LabelSection>논문 내용</LabelSection>
                                    <TextArea
                                        placeholder='내용을 입력하세요.'
                                        defaultValue={getContent}
                                        onKeyUp={(e) => setContentHandle(e)}
                                    >
                                    </TextArea>
                                </Column>
                        </RowTable>
                    </ContentTable>
                    <ContentButton>
                    <ContentButtonCenter>
                        <LinkStyle to="/list">목록</LinkStyle>

                        <ControllerButton 
                            status="register"
                            type={pageStatus ? "update" : "register"}
                            onClick={registerHandle}
                        >
                            <span>{pageStatus ? "저장" : "등록"}</span>
                        </ControllerButton>
                    </ContentButtonCenter>
                    </ContentButton>
                </ContentBox>
            </Content>
        </Container>
    )
}

export default ArticelDetail;

const Container = styled.div`
    width: calc(100% - 200px);
    min-width: 1500px;
    height: 100%;
    padding-top: 20px;
    position: relative;
    display: flex;
    justify-content: center;
`;

const Content = styled.div`
    width: calc(100% - 100px);
    min-height: 540px;
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
`;

const ContentBox = styled.div`
    width: 100%;
    min-width: 720px;
    height: 720px;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
`;

// ContentBox > ContentHeader
const ContentHeader = styled.div`
    width: 100%;
    height: 60px;
    padding: 20px;
    h2 {
        color: #ff9900;
        font-weight: bold;
    }
`;

// ContentBox > ContentTable
const ContentTable = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 30px;
`;

const RowTable = styled.div`
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #d5d5d5;
    border-top: 3px solid #444;
`;

const Column = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #d5d5d5;
`;

const Row = styled.div`
    width: 50%;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;

`;

const ContentButton = styled.div`
    width: 100%;
    height: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
`;

const ContentButtonCenter = styled.div`
    display: flex;
`;

const LabelSection = styled.div`
    width: 200px;
    height: 100%;
    background-color: #f5f5f5;
    padding: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    border-right: 1px solid #d5d5d5;
`;

const TextArea = styled.textarea`
    width: calc(100% - 200px);
    border: none;
    resize: none;
    outline: none;
    padding: 20px;
`;

const FileSection = styled.div`
    width: calc(100% - 200px);
    display:flex;
    align-items: center;
    padding-left: 20px;
`;

const InputFile = styled.input`
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
`;

const InputText = styled.input`
    width: calc(100% - 200px);
    padding: 10px;
    padding-left: 20px;
    border: 0;
    outline: none;
`;

const ControllerButton = styled.div`
    display:flex;
    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
    }

    justify-content: center;
    align-items: center;

`;

const LinkStyle = styled(Link)`
    display:flex;
    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
        color:#fff;
    }

    justify-content: center;
    align-items: center;
`

const OldImageFile = styled.div`
    width: 100%
    height: 100%;
    padding: 5px;
    background-color: #f5f5f5;
    font-weight: bold;
`;

const LabelStyle = styled.label`
    font-size: 12px;
    font-weight: 500;
    color: #8d8d8d;
    margin-right: 200px;
    width: calc(100%-120px);
    &:hover {
        cursor: pointer;
    }
`;

const DatePickerStyle = styled.div`
    width: calc(100% - 200px);
    height: 100%;
    text-align: center;
    border: none;
    padding: 0;
    margin: 0;
    display:flex;
    align-items: center;
`;

const DatePickerReStyle = styled(DatePicker)`
    width: 180px;
    height: 30px;
    text-align: center;
    border: solid 1px #f90;
    outline: none;
    position: relative;
    font-size: 14px;
    font-weight: 700;
    background-color: #f90;
    color: #ffffff;
    border-radius: 3px;
    margin-left: 30px;
`

const YearSelectBox = styled.select`
    width: calc(100% - 220px);
    border: none;
    outline: none;
    &:hover {
        cursor: pointer;
    }
    option {
        text-align: center;
    }
`;

const MonthSelectBox = styled.select`
    width: calc(100% - 220px);
    border: none;
    outline: none;
    &:hover {
        cursor: pointer;
    }
    option {
        text-align: center;
    }
`;