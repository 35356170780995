import React from 'react';
import { useLocation } from "react-router-dom";

import { Breadcrumb, Layout, Menu, Button } from 'antd';
import { DesktopOutlined, FolderOpenOutlined, LogoutOutlined } from '@ant-design/icons';

import styled from 'styled-components';


const Footer = () => {
  return(
    <Container>
      Footer
    </Container>
  )
}

export default Footer;


const Container = styled.div`
  width: 100%;
  height: 60px;
  background-color: blue;
`;