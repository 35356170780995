import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Table from './components/Table';

import {list} from '../../../api/List';

import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingStateSelect } from '../../../atom/state';

const HistoryTable = () => {

  const [ tableData, setData ] = useState([]);
  const [ _loading, setLoading ] = useRecoilState(loadingStateSelect);

  useEffect(() => {
    let check = list('histories');
    check.then((res) => { 
      setData(res.data.data.histories);
      setLoading(false);
    });
  }, [])

  const dataColumns = [
    {  id: "번호",  style: { width: '100px', }, type: 'text' },
    {  year: "연혁연도",  style: { width: '10%', }, type: "text"},
    {  month: "연혁월", style: { width: '10%', }, type: "text" },
    {  content: "내용", style: { width: '50%', }, type: "text" },
    {  created_at: "등록일", style: { 'width': '100px', }, type: "date" }
  ];

  // 데이터 열에 대한 스타일 정의
  const styleSet = {
    id: {'textAlign' : 'center' },
    year: {'textAlign' : 'center', },
    month: {'textAlign' : 'center', 'textOverflow' : 'clip',},
    content: {'textAlign' : 'center', },
    created_at: {'textAlign' : 'center' },
  }
  
  return(
    <Container>
        <Table
          columns={dataColumns}
          data={tableData}
          style={styleSet}
        />
    </Container>
  )
}

export default HistoryTable;


const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color:blue;
`;