import { DesktopOutlined, FolderOpenOutlined, LogoutOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import '../css/Main.css';
import axios from 'axios'

import Header from './template/Header';
import Menu from './template/Menu';

import ContentHeader from './template/organism/components/ContentHeader';
import BannerDetail from './template/organism/BannerDetail';
import PopupDetail from './template/organism/PopupDetail';
import HistoryDetail from './template/organism/HistoryDetail';
import ArticelDetail from './template/organism/ArticelDetail';
import PatentDetail from './template/organism/PatentDetail';
import NewsDetail from './template/organism/NewsDetail';
import NoticeDetail from './template/organism/NoticeDetail';
import RecruitsDetail from './template/organism/RecruitsDetail';

import { useLocation } from 'react-router-dom';

const Detail = (props) => {

  const [ getMenu, setMenu ] = useState('');
  let selectMenuLocal = localStorage.getItem('menu');

  useEffect(() => {
    if(selectMenuLocal) {
      setMenu(selectMenuLocal);
    }
  });

  const useLocationState = useLocation();
  
  let olderData = [];
  if(useLocationState.state) {
    olderData = useLocationState.state.data;
  }
  
  const selectComponent = {
    banners:    <BannerDetail       data={olderData}/>,
    popups:     <PopupDetail        data={olderData} />,
    histories:  <HistoryDetail      data={olderData} />,
    articels:   <ArticelDetail      data={olderData} />,
    patents:    <PatentDetail       data={olderData} />,
    news:       <NewsDetail         data={olderData} />,
    notices:    <NoticeDetail       data={olderData} />,
    recruits:   <RecruitsDetail     data={olderData} />,
  };

  return(
    <Container>
      <Header />
      <ContentSection>
        <Menu />  
        {selectComponent[getMenu]}
      </ContentSection>
    </Container>
  )
}

export default Detail;

const Container = styled.div`
  width: 100%;
  min-width: 768px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentSection = styled.div`
  width: 100%;
  height: calc( 100% - 72px );  
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  position: fixed;
  top: 72px;
  left: 0px;
`;