import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';
import Header from './template/Header';
import Menu from './template/Menu';
import Content from './template/Content';
import Footer from './template/Footer';

import { menuState, selectMenuState, loadingStateSelect } from '../atom/state';
import { useRecoilState, useRecoilValue } from 'recoil';

if(!window.localStorage.getItem('access_token')) {
  alert("유효하지 않은 접근입니다. 재 로그인해주세요.");
}

const List = () => {

  const [ getMenu, setMenu ] = useRecoilState(menuState);
  const menu = useRecoilValue(menuState);
  const nowMenu = useRecoilValue(selectMenuState);
  const data = useLocation();

  let subMenuKey = 0;

  useEffect(() => {

    if(data.state) { 
      subMenuKey = data.state.type;
    }

    menu.subMenuList.map((item, idx) => {
      if(item.id == subMenuKey) {
        setMenu(menu.subMenuList[idx]);
        return;
      }
    });

    if(nowMenu) {
      // console.log(nowMenu.list);
      localStorage.setItem('menu', nowMenu);
    }
});

  return(
    <Container>
      <Header />
      <ContentSection>
        <Menu />  
        <Content 
          info={nowMenu}
        />
      </ContentSection>
    </Container>
  )
  
}

export default List;

const Container = styled.div`
  width: 100%;
  min-width: 768px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentSection = styled.div`
  width: 100%;
  height: calc( 100% - 72px );  
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  position: fixed;
  top: 72px;
  left: 0px;
`;