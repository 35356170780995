import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";

import { Breadcrumb, Layout, Menu, Button } from 'antd';
import { DesktopOutlined, FolderOpenOutlined, LogoutOutlined } from '@ant-design/icons';

import styled from 'styled-components';
import logo from '../../images/LOGO-W.svg';
import { useRecoilState } from 'recoil';
import { language } from '../../atom/state';
import { useRef } from 'react';

const Header = () => {

  const toggle = useRef(null);

  const [ type, setType ] = useRecoilState(language);

  const toggleMoveOnclickHandle = (typeStr) => {

    if(toggle.current) {

      if(typeStr === 'ko') toggle.current.style.transform = 'translateX(0px)';
      if(typeStr === 'en') toggle.current.style.transform = 'translateX(50px)';

      window.localStorage.setItem('language', typeStr);
      setType(typeStr);

      setTimeout(() => {
          window.location.reload();    
      }, 600);
    }

    setInterval(() => {
      window.location.reload();
    }, 700);

  }

  useEffect(() => {
    if(type === 'ko') {
      if(toggle.current) toggle.current.style.transform = 'translateX(0px)';
      window.localStorage.setItem('language', type);
  } else {
      if(toggle.current) toggle.current.style.transform = 'translateX(50px)';
      window.localStorage.setItem('language', type);
  }
  }, []);

  return(
    <Container>
      <Logo>
        <Image className='cp_log' src={logo}></Image>
      </Logo>

      <HeaderContent>

        <ToggleLanguage>

            <div 
              className='kor'
              onClick={(e) => toggleMoveOnclickHandle('ko')}
            >
              <span>ko</span>
            </div>

            <div 
              className='en'
              onClick={(e) => toggleMoveOnclickHandle('en')}
            >
              <span>en</span>
            </div>

            <div 
              ref={toggle}
              className='toggleMoveItem' 
            />            

        </ToggleLanguage>

      </HeaderContent>
      
    </Container>
  )
}

export default Header;

const Container = styled.div`
  width: 100%;
  height: 72px;
  background-color: #444;
  display: flex;
  flex-direction: row;
`;

const Logo = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}`;

const Image = styled.img`
  width: 172px;
`;

const HeaderContent = styled.div`
  width: calc( 100% - 200px );
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 40px;
`;

const ToggleLanguage = styled.div`

  position: relative;
  width: 100px;
  height: 30px;
  background-color: white;
  border-radius: 50px;
  box-shadow: rgb(255 255 255) 0px 0px 4px 0px;

  display: flex;
  flex-direction: row;

  font-weight: bold;
  font-size: 1rem;

  .kor {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &:hover {
      cursor: pointer;
      color: red;
    }
  }

  .en {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &:hover {
      cursor: pointer;
      color: red;
    }
  }

  .toggleMoveItem {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    border-radius: 50px;
    background-color: #ff990196;
    // opacity: 0.7;
    transition: all 0.7s;
    z-index: 9;
  }
`;