import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {createBanner, updateBanner} from '../../../api/List';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingStateSelect } from '../../../atom/state';

const BannerDetail = (props) => {

    const [ topTitle, setTopTitle ] = useState('');
    const [ bottomTitle, setBottomTitle ] = useState('');
    const [ bannerImageFile, setBannerImageFile ] = useState(null);
    const [ tableData, setData ] = useState([]);
    const [ _loading, setLoading ] = useRecoilState(loadingStateSelect);
    const [ oldImageFile, setOldImageFile ] = useState('');

    const filesUploadHandle = (e) => { setBannerImageFile(e.target.files[0]); }
    const topTitleHandle    = (e) => { setTopTitle(e.target.value); }
    const bottomTitleHandle = (e) => { setBottomTitle(e.target.value); }

    useEffect(() => {
        if(props.data){
            setTopTitle(props.data.topTitle);
            setBottomTitle(props.data.bottomTitle);
        }
    }, []);

    const registerHandle = (e) => {
        
        if ( !topTitle || !bottomTitle ) {
            alert("문구 또는 이미지를 모두 입력해주세요.");
            return;
        }

        if ( !oldImageFile ) {
            if ( !bannerImageFile ) { 
                alert("이미지를 입력해주세요.");
                return;
            }
        }

        if ( topTitle == props.data.topTitle ) {
            if ( bottomTitle == props.data.bottomTitle ) {
                if ( oldImageFile ) {
                    window.location.href = "/list";
                    return;
                }
            }
        }

        if ( oldImageFile ) {
            setBannerImageFile('');
        } else {
            if ( !bannerImageFile ) {
                setBannerImageFile(null);
            }
        }  

        if(e.target.getAttribute("type") == "register") {

            let data = {topTitle : topTitle, bottomTitle : bottomTitle, bannerImageFile : bannerImageFile}
            let check = createBanner('registerBanner', data);
            check.then((res) => {
                if(res.data.success) {
                    window.location.href = "/list";
                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                }
            });

        } else {

            let data = {id: props.data.id, topTitle : topTitle, bottomTitle : bottomTitle, bannerImageFile : bannerImageFile}
            let check = updateBanner('updateBanner', data);
            check.then((res) => {
                if(res.data.success) {
                    window.location.href = "/list";
                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                }
            });

        }
    }

    let filename = "";

    if(props.data.file_addr) {
        let imageUrl = props.data.file_addr;
        let urlStrSplit = imageUrl.split("/");
        filename = urlStrSplit[urlStrSplit.length-1];
    }

    const imageDeleteHandle = () => {
        setOldImageFile("");
    }

    const resetTopDataHandle = (e) => {
        if(!topTitle) {
            e.target.value = "";
            setTopTitle('');
        }
    }

    const resetBottomDataHandle = (e) => {
        if(!bottomTitle) {
            e.target.value = "";
            setBottomTitle('');
        }
    }

    useEffect(() => {
        setOldImageFile(filename);
    }, [])

    return (
        <Container>
            <Content>
                <Header>
                    <h2>메인배너 관리</h2>
                </Header>
                <ContentBox>
                    <ContentHeader>
                        <h2>메인배너 { topTitle ? "수정" : "등록"}</h2>
                    </ContentHeader>
                    <ContentTable>
                        <RowTable>
                            <TopTitle>
                                <LabelSection>상단 문구</LabelSection>
                                <TextArea 
                                    placeholder='대제목을 입력해주세요.&#13;&#10;(ENTER키로 줄바꿈 반영&#13;&#10;/ 최대 2줄까지)'
                                    onChange={topTitleHandle}
                                    onClick={resetTopDataHandle}
                                    value={topTitle}
                                />
                            </TopTitle>
                                
                            <BottomTitle>
                                <LabelSection>하단 문구</LabelSection>
                                <TextArea 
                                    placeholder='대제목을 입력해주세요.&#13;&#10;(ENTER키로 줄바꿈 반영&#13;&#10;/ 최대 2줄까지)'
                                    onChange={bottomTitleHandle}
                                    onClick={resetBottomDataHandle}
                                    value={bottomTitle}
                                />
                            </BottomTitle>
                        </RowTable>

                        <ImageSelect>
                            <LabelSection >배경 이미지</LabelSection>
                            <FileSection>
                                { oldImageFile ? 
                                    <OldImageFile onClick={imageDeleteHandle}>
                                        {filename} X
                                    </OldImageFile> 
                                    :
                                    <InputFile 
                                        type="file"
                                        accept='image/jpg, impage/png, image/jpeg, image/webp'
                                        onChange={filesUploadHandle}
                                    /> 
                                }
                            </FileSection>
                        </ImageSelect>

                    </ContentTable>
                    <ContentButton>
                    <ContentButtonCenter>
                        <LinkStyle to="/list">목록</LinkStyle>

                        <ControllerButton 
                            status="register"
                            type={props.data.topTitle ? "update" : "register"}
                            onClick={(e) => registerHandle(e)}
                        >
                            <span>{props.data ? "저장" : "등록"}</span>
                        </ControllerButton>
                    </ContentButtonCenter>
                    </ContentButton>
                </ContentBox>
            </Content>
        </Container>
    )
}

export default BannerDetail;

const Container = styled.div`
    width: calc(100% - 200px);
    height: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
`;

const Content = styled.div`
    width: calc(100% - 100px);
    min-height: 540px;
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    
`;

const ContentBox = styled.div`
    width: 100%;
    min-width: 720px;
    height: 420px;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
`;

// ContentBox > ContentHeader
const ContentHeader = styled.div`
    width: 100%;
    height: 60px;
    padding: 20px;
    h2 {
        color: #ff9900;
        font-weight: bold;
    }
`;

// ContentBox > ContentTable
const ContentTable = styled.div`
    width: 100%;
    height: 250px;  
    display: flex;
    flex-direction: column;
    padding: 30px;
`;

const RowTable = styled.div`
    width: 100%;
    height: 70%;
    display: flex;
    flex-directrion: row;
    border: 1px solid #d5d5d5;
    border-top: 3px solid #444;
`;

const TopTitle = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
`;

const BottomTitle = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
`;

const ImageSelect = styled.div`
    widht: 100%;
    height: 30%;
    border: 1px solid #d5d5d5;
    border-top: none;
    display:flex;
`;

const ContentButton = styled.div`
    width: 100%;
    height: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
`;

const ContentButtonCenter = styled.div`
    display: flex;
`;

const LabelSection = styled.div`
    width: 200px;
    height: 100%;
    background-color: #f5f5f5;
    padding: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    border-right: 1px solid #d5d5d5;
`;

const TextArea = styled.textarea`
    width: calc(100% - 200px);
    height: 100%;
    border: none;
    resize: none;
    outline: none;
    padding: 20px;
`;

const FileSection = styled.div`
    width: calc(100% - 200px);
    display:flex;
    align-items: center;
    padding-left: 20px;
`;

const InputFile = styled.input`
    
`;

const ControllerButton = styled.div`
    display:flex;

    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
    }

    justify-content: center;
    align-items: center;

`;

const LinkStyle = styled(Link)`
    display:flex;
    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
        color:#fff;
    }

    justify-content: center;
    align-items: center;
`

const OldImageFile = styled.div`
    width: 100%
    height: 100%;
    padding: 5px;
    background-color: #f5f5f5;
    font-weight: bold;
`;