import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { menuState } from '../../../../atom/state';
import { useRecoilState, useRecoilValue } from 'recoil';


const ContentHeader = (props) => {

    const [ data, setData ] = useState(null);
    const selectMenuLocal = localStorage.getItem('menu');
    const [ getMenu, setMenu ] = useState('');
    const [ menu ] = useRecoilState(menuState);

    useEffect(() => {
      if(props){
        setData(props);
      }

      let menuArray = menu.subMenuList;

      menuArray.map((item) => {
        if(item.list == selectMenuLocal) {
            setMenu(item);
            return;
        }
      })
    });

    return(
        <Container>
            {/* props now location */}
            <HeaderText>
                {getMenu.name}
            </HeaderText>
        </Container>
    )
}

export default ContentHeader;


const Container = styled.div`
    width: calc(100% - 64px);
    min-width: 768px;
    height: 56px;
    margin: 32px;
    margin-bottom: 0px;
    padding-left: 20px;
`;

const HeaderText = styled.span`
    width: 118px;
    height: 30px;
    font-size: 21px;
    font-weight: bold;
    color: #333;
`;