import axios from 'axios';
import { BASE_URL } from './Config';

export const refreshToken = () => {

    let success = false;

    axios.post(BASE_URL+ "refreshToken" , {
        refresh_token: window.localStorage.getItem("refresh_token")
    })
    .then((Response) => {
        if (Response.data.success) {
            localStorage.setItem('access_token', Response.data.data.token.access_token);
            localStorage.setItem('refresh_token', Response.data.data.token.refresh_token);
            success = true;
        } else {
            if (!Response.data.data.msg) {
                alert(Response.data.msg);
            }
            success = false;
        }
    })
    .catch((Error) => { console.log(Error) })

    return success;
};

// 테이블 전반 데이터 접근을 위한 함수

export const list = async (url) => {

    const response = await axios.post(BASE_URL+ url, null , {
        headers : {
            "Content-Type": "application/json",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return list(url);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response;
};


export const detail = async (url, id) => {
    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "application/json",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: { id: id},
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return detail(url, id);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });
    return response; 
};


// 배너 상세 : 사용하지 않음
export const detailBanner = async (url, data) => {
    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: data,
    });
    return response; 
};

// list in delete action : 공통 함수로 동작의 커맨드어는 type 으로 분기된다.
export const deleteData = async (url, data, listType) => {
    const myArr = Array.from(data);
    const response = await axios({
        method: "POST",
        url: BASE_URL + 'removeList',
        headers : {
            "Content-Type": "application/json",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
        },
        data : { ids: myArr, type: listType, }
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return deleteData(url, data. listType);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response;
}; //

// 배너 생성
export const createBanner = async (url, data) => {

    const formData = new FormData();
    formData.append("topTitle",data.topTitle);
    formData.append("bottomTitle",data.bottomTitle);
    formData.append("bannerImageFile",data.bannerImageFile);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'access-control-allow-origin': '*',
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return createBanner(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}; //

// 배너 업데이트
export const updateBanner = async (url, data) => {
    
    const formData = new FormData();

    formData.append("id",data.id);
    formData.append("topTitle",data.topTitle);
    formData.append("bottomTitle",data.bottomTitle);
    formData.append("bannerImageFile",data.bannerImageFile);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'access-control-allow-origin': '*',
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return updateBanner(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}

/* 
 * POPUP
*/

// 팝업 생성
export const createPopup = async (url, data) => {

    const formData = new FormData();
    formData.append("title",data.title);
    formData.append("link",data.link);
    formData.append("image",data.image);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'access-control-allow-origin': '*',
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return createPopup(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}; //

// 팝업 업데이트
export const updatePopup = async (url, data) => {
    
    const formData = new FormData();

    formData.append("id", data.id);
    formData.append("title", data.title);
    formData.append("link", data.link);
    formData.append("image", data.image);
    console.log(data.image);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'access-control-allow-origin': '*',
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return updatePopup(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}




/* 
 * History
*/

// 히스토리 생성
export const createHistory = async (url, data) => {
    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "application/json",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: {year: data.year, month: data.month, content: data.content,},
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return createHistory(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}; //

// 히스토리 업데이트
export const updateHistory = async (url, data) => {
    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "application/json",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: {id: data.id, year:data.year, month:data.month, content:data.content,},
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return updateHistory(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}


/* 
 * Patents
*/

// 팝업 생성
export const patentRegister = async (url, data) => {

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("image", data.image);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return patentRegister(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });
    
    return response; 
}; //

// 팝업 업데이트
export const patentUpdate = async (url, data) => {
    
    const formData = new FormData();

    formData.append("id", data.id);
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("image", data.image);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return patentUpdate(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}



/* 
 * News
*/

export const newsRegister = async (url, data) => {

    const formData = new FormData();
    
    formData.append("check", data.check);
    formData.append("title",data.title);
    formData.append("content",data.content);
    formData.append("file",data.file);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'access-control-allow-origin': '*',
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return newsRegister(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}; //

// News Update
export const newsUpdate = async (url, data) => {
    
    const formData = new FormData();

    formData.append("check", data.check);
    formData.append("id", data.id);
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("file", data.file);
    formData.append("oldFileIsDelete", data.oldFileIsDelete);
    
    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return newsUpdate(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}

/* 
 * Notice
*/

export const noticeRegister = async (url, data) => {

    const formData = new FormData();
    
    formData.append("check", data.check);
    formData.append("title",data.title);
    formData.append("content",data.content);
    formData.append("file",data.file);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return noticeRegister(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}; //

// News Update
export const noticeUpdate = async (url, data) => {
    
    const formData = new FormData();

    formData.append("check", data.check);
    formData.append("id", data.id);
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("file", data.file);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return noticeUpdate(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}

/* 
 * Recruits
*/

export const recruitsRegister = async (url, data) => {

    const formData = new FormData();
    
    formData.append("check", data.check);
    formData.append("title",data.title);
    formData.append("content",data.content);
    data.files.forEach(file => {
        formData.append("files[]", file);
    });

    formData.append("start_date",data.start_date);
    formData.append("end_date",data.end_date);
    formData.append("footers", data.footers);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return recruitsRegister(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}; //

// News Update
export const recruitsUpdate = async (url, data) => {
    
    const formData = new FormData();

    formData.append("check", data.check);
    formData.append("id", data.id);
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("footers", data.footers);

    // formData.append("files", data.files);
    
    data.files.forEach(file => {
        formData.append("files[]", file);
    });

    formData.append("start_date", data.start_date);
    formData.append("end_date", data.end_date);
    formData.append("removeFiles", Array.from(data.removeFiles));

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return recruitsUpdate(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}

/* 
 * Articels
*/

export const articelsRegister = async (url, data) => {

    const formData = new FormData();
    
    formData.append("title", data.title);
    formData.append("short_title", data.short_title);
    formData.append("writer_info", data.writer_info);
    formData.append("writer", data.writer);
    formData.append("link", data.link);    
    formData.append("register_date", data.register_date);
    formData.append("content", data.content);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return articelsRegister(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}; //

// articels Update
export const articelsUpdate = async (url, data) => {
    
    const formData = new FormData();

    formData.append("id", data.id);

    formData.append("title", data.title);
    formData.append("short_title", data.short_title);
    formData.append("writer_info", data.writer_info);
    formData.append("writer", data.writer);
    formData.append("link", data.link);    
    formData.append("register_date", data.register_date);
    formData.append("content", data.content);

    const response = await axios({
        method: "POST",
        url: BASE_URL + url,
        headers : {
            "Content-Type": "multipart/form-data",
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data: formData,
    })
    .catch((response) => {
        if(response.response.status == 401) {
            if(refreshToken()) {
                return articelsUpdate(url, data);
            } else {
                alert("로그인 유효 기간이 만료되었습니다.");
                window.location.href = "/";
            }
        }
    });

    return response; 
}