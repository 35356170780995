import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { menuState, selectMenuState, menuHideState, loadingState, loadingStateSelect } from '../../atom/state';

import ContentHeader from './organism/components/ContentHeader';
import BannerTable from './organism/BannerTable';
import PopupTable from './organism/PopupTable';
import HistoryTable from './organism/HistoryTable';
import ArticelTable from './organism/ArticelTable';
import PatentTable from './organism/PatentTable';
import NewsTable from './organism/NewsTable';
import NoticeTable from './organism/NoticeTable';
import RecruitsTable from './organism/RecruitsTable';

import Loader from './organism/components/Loading';

const Content = (props) => {

  // const [ loading, setLoading] = useState(false);
  const [ data, setData ] = useState(null);
  const [ selectContent, setContent ] = useState("banners");
  const [ hideStatus , setHide ] = useRecoilState(menuHideState);

  const [ _loading, setLoading ] = useRecoilState(loadingStateSelect);
  
  const nowMenu = useRecoilValue(selectMenuState);
  const loading = useRecoilValue(loadingState);

  let loadingComponent; 

  if(nowMenu) {
    localStorage.setItem('menu', nowMenu.list);
  }

  useEffect(() => {

    let selectMenuLocal = localStorage.getItem('menu');
    
    if(props){
      setData(props.info);

      if(nowMenu) {
        setContent(nowMenu.list);
      } else {
        if(selectContent) {
          if(selectMenuLocal) {
            setContent(selectMenuLocal);
          } 
        }
      }
    }
    setLoading(true);

  }, [nowMenu]);

  if(loading) {
    loadingComponent = <Loader type="spin" color="#f90" />;
  }

  const selectComponent = {
    banners:   <BannerTable    data={data} />,
    popups:    <PopupTable     data={data} />,
    histories:  <HistoryTable   data={data} />,
    articels:  <ArticelTable   data={data} />,
    patents:   <PatentTable    data={data} />,
    news:     <NewsTable      data={data} />,
    notices:   <NoticeTable    data={data} />,
    recruits: <RecruitsTable  data={data} />,
  };


  const menuHide = () => {
    hideStatus ? setHide(false) : setHide(true);
  }

  return(
    <Container status={hideStatus}>
      {loadingComponent}
      {/* <HideButton onClick={menuHide}>
        {hideStatus ? "〉" : "〈"}
      </HideButton>     */}
      <ContentHeader content={data} />
      <ContentSection>
        {selectComponent[selectContent]}
      </ContentSection>
    </Container>
  )
}

export default Content;


const Container = styled.div`
  width: ${(props) => (props.status ? "100%;" : "calc(100% - 200px);")};
  transition: all 0.5s linear;
  height: 100%;
  background-color: #f9f9f9;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;


const ContentSection = styled.div`
  width: calc(100% - 64px);
  min-width: 768px;   
  margin: 32px;
  margin-top: 0px;
`;


const HideButton = styled.button`
  position: relative;
  top: 0px;
  left: 0px;
  width: 32px;
  height: 56px;
  background:#6d6d6d;
  color: white;
  font-weight: bold;
  font-size: 15px;
  border: none;
`;