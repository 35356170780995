import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <RecoilRoot>
        <App />
    </RecoilRoot>
);




// 스트릭 모드를 사용하는 이유는 애플리케이션 내 잠재적 문제를 알아내기 위한 도구이다.

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
