import { Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { dateFormatYYYYMMDD } from '../../../../util/DateFormat';

import InputButton from '../components/InputButton';

import { useRecoilState, useRecoilValue } from 'recoil';
import { selectMenuState, loadingStateSelect } from '../../../../atom/state';
import { Link } from 'react-router-dom';

const Table = (props) => {

    // Props 를 통해 전달된 제목 컬럼 열과 데이터의 매칭을 위한 코드 작성
    // const columns = [];
    // const dataColumns = props.columns;
    const data = props.data;

    const [dataColumns, setDataColumns] = useState([]);
    const [getData, setData] = useState([]);
    const [checkList, setCheckList] = useState([]);
    const [isAllChecked, setAllChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState(new Set());
    const [editIndex, setEditIndex] = useState(new Set());
    const [columns, setColumns] = useState([]);
    const [ _loading, setLoading ] = useRecoilState(loadingStateSelect);
    const nowMenu = useRecoilValue(selectMenuState);

    useEffect(() => {

        if(data.length > 0) {
            setData(data);
            setCheckList(()=> data.map(() => false));
        }

        if(dataColumns.length > 0 && data.length > 0) {
            Object.entries(data[0]).map((column) => {
                setColumns(columns => [...columns, column[0]]);
            });
        }
        setDataColumns(props.columns);
        // setLoading(false);

    }, [data])
    
    const onclickDetail = e => {
        // 수정 버튼 클릭 시, 디테일 화면으로 이동
        // 링크 이동 및 전역 변수 내, 디테일 화면 id 데이터 저장
        
        // console.log(e.target.getAttribute('data'));
        // console.log(e.target.getAttribute('type'));

    }


    const handleCheckClick = (index, checked, id) => {

        setCheckList((checks) => checks.map((c, i) => (i === index ? ! c : c)));

        if(checked) {
            
            editIndex.add(id);
            // console.log(editIndex);
            checkedItems.add(index);
            setCheckedItems(checkedItems);

        } else {

            editIndex.delete(id);
            checkedItems.delete(index);

        }

        if(checkList.length === checkedItems.size) {

            setAllChecked(true);

        } else {

            setAllChecked(false);

        }

    }

    const isAllCheckedHandle = (checked) => {

        if(checked) {

            setAllChecked(true);
            setCheckList((checks) => checks.map((c) => c = true));
            checkList.map((c, index) => { checkedItems.add(index); });
            data.map((item) => { editIndex.add(item.id); });

        } else {

            setAllChecked(false);
            checkedItems.clear();
            editIndex.clear();
            setCheckList((checks) => checks.map((c) => c = false));
            
        }
    }

    return(
        <Container>
            <ControllBox>
                
                <ControllerButton
                    name="선택삭제"
                    type="select"
                    data={editIndex}
                />
                
                <ControllerButton
                    name="등록"
                    type="register"
                >   
                </ControllerButton>

            </ControllBox>

            <ResultCount>
                <h3>
                총
                    <ResultTextColor >{data.length}</ResultTextColor>
                건
                </h3>
            </ResultCount>

            <TableWraper>
                <Thead>
                    <TableRow>
                        <TableData className='editColumn' style={{width: "20px"}}>
                            <CheckboxStyle
                                checked={isAllChecked}
                                onClick={(e) => isAllCheckedHandle(e.target.checked)}
                            />
                        </TableData>

                        { dataColumns.map((item, idx) => {

                            return <TableData style={item.style} className="editColumn" key={idx} >{dataColumns[idx][columns[idx]]}</TableData>

                        })}

                        <TableData className='editColumn'>
                            <span>관리</span>
                        </TableData>

                    </TableRow>
                </Thead>
                <Tbody>
                    {
                        data.map((i, idx) => {
                            return (
                                <TableRow key={idx}>
                                    <TableData>
                                        <CheckboxStyle 
                                            checked={checkList[idx]} 
                                            onClick={(e) => handleCheckClick(idx, e.target.checked, i.id)}
                                        />
                                    </TableData>

                                    { columns.map((j, jdx) => {
                                        if(dataColumns[jdx]) {
                                            if(dataColumns[jdx].type == "img") {
                                                return <TableData style={Object.assign({}, props.style[j])} className={j} key={jdx}> 
                                                            <img src={i[j]}/>
                                                       </TableData>

                                            } else if(dataColumns[jdx].type == "date") {
                                                return <TableData style={Object.assign({}, props.style[j])} className={j} key={jdx}> {
                                                    dateFormatYYYYMMDD(i[j])
                                                } </TableData>

                                            } else if(dataColumns[jdx].type == "type") {
                                                return <TableData style={Object.assign({}, props.style[j])} className={j} key={jdx}> {
                                                    i[j] == 3 ? "공지중" : ''
                                                } </TableData>

                                            }  else if(dataColumns[jdx].type == "status") {
                                                return <TableData style={Object.assign({}, props.style[j])} className={j} key={jdx}> {
                                                    i[j] == 1 ? "채용중" : "마감"
                                                } </TableData>

                                            } else {
                                                return <TableData style={Object.assign({}, props.style[j])} className={j} key={jdx}> {
                                                            i[j]
                                                       } </TableData>
                                            }
                                        }
                                    })}

                                    <TableData className='editButton'>
                                        <EditButton
                                            to="/detail"
                                            state={{ data: i }}
                                        >
                                        수정
                                        </EditButton>
                                    </TableData>
                                </TableRow>
                            );
                        })
                    }
                </Tbody>
            </TableWraper>

        </Container>
    )
}

export default Table;


const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`;


const TableWraper = styled.table`
    width: calc(100% - 10px);
    height: 100%;
    margin: 5px;
    box-shadow: 2px 2px 2px #b1a2a2;
`;


const Thead = styled.thead`
    background-color: black;
    color: white;
    border: none;
    background-color: #444;
    height: 48px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
`;


const Tbody = styled.tbody`
    background-color: white;
    color: black;
    border: none;
`;


const TableRow = styled.tr`
    border: none;
    border: 1px solid #e8e8e8;
`;


const TableData = styled.td`
    border: solid 1px #e8e8e8;
    padding: 12px;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line

    &:first-child {
        width: 55px;
        text-align: center;
    }

    &.editColumn {
        height: 48px;
        border: 1px solid #444;
        &:last-child {
            width: 112px;
        }
    }

    &.editButton {
        display: flex;
        border: none;
        width:100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        
        &:hover {
            cursor: pointer;
        }
    }

    &.id {
        width: 32px;
    }

    &.file_addr {
        img {
            width: 48px;
            height: 48px;
        }
    }

`;


const EditButton = styled(Link)`
    width: 72px;
    height: 32px;
    border: solid 1px #f90;
    background-color: #fff;
    color: #f90;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        color: #f90;
    }
`;


const ResultCount = styled.div`
    margin-left: 5px;
    width: 150px;
    display: inline-block;
`;


const ResultTextColor = styled.span`
    color: #f90;
    font-weight: bold;
    margin-left: 5px;
    display: inline-block;
`


const ControllBox = styled.div`
  width: 100%;
  height: 20px;
  align-items: right;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 5px;
`;


const ControllerButton = styled(InputButton)`
    // 선택삭제, 등록
`

const CheckboxStyle = styled(Checkbox)`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;