import axios from 'axios';
import { BASE_URL } from './Config';


export async function loginCheck(url, user) {
    
    let success = false;

    await axios.post("https://api.plabiologics.com/api/"+ url , {
        email: user.username,
        password: user.password
    })
    .then((Response) => {
        if (Response.data.success) {
            localStorage.setItem('access_token', Response.data.data.token.access_token);
            localStorage.setItem('refresh_token', Response.data.data.token.refresh_token);
            success = true;
        } else {
            if (!Response.data.data.msg) {
                alert(Response.data.msg);
            }
            success = false;
        }
    })
    .catch((Error) => { console.log(Error) })

    return success;
};