import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Table from './components/Table';

import {list} from '../../../api/List';

import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingStateSelect } from '../../../atom/state';

const RecruitsTable = () => {

  const [ tableData, setData ] = useState([]);
  const [ _loading, setLoading ] = useRecoilState(loadingStateSelect);

  useEffect(() => {
    let check = list('recruits');
    check.then((res) => { 
      setData(res.data.data.recruits);
      setLoading(false);
    });
  }, [])

  const dataColumns = [
    {  id: "번호",  style: { width: '100px', }, type: 'text' },
    {  status: "상태",  style: { width: '80px', }, type: 'status' },
    {  title: "제목",  style: { width: '40%', }, type: "text" },
    {  start_date: "채용시작",  style: { width: '100px', }, type: "text"},
    {  end_date: "채용마감일",  style: { width: '100px', }, type: "text"},
  ];

  // 데이터 열에 대한 스타일 정의
  const styleSet = {
    id: {'textAlign' : 'center' },
    status: {'textAlign' : 'center', },
    title: {'textAlign' : 'center', },
    start_date: {'textAlign' : 'center', 'fontSize' : '11px' },
    end_date: {'textAlign' : 'center',  'fontSize' : '11px'  },
  }
  
  return(
    <Container>
        <Table
          columns={dataColumns}
          data={tableData}
          style={styleSet}
        />
    </Container>
  )
}

export default RecruitsTable;


const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color:blue;
`;