import { atom, selector } from 'recoil';


/*
 * ==========================================================================
 */

export const selectMenuState = atom({
    key: 'selectMenuState',
    default: null,
});

export const menuState = selector({
    key: 'menu',

    get: ({get}) => {
        const menuList = {
            // 메뉴 타이틀 배열
            titleMenuList: [
                { id: 0, name: "메인페이지 관리", },
                { id: 1, name: "서브페이지 관리", },
            ],

            // 하위 메뉴 배열
            subMenuList: [
                { menuId: 0, id:3,  name: "메인배너 관리", list: "banners" },
                { menuId: 0, id:4,  name: "메인팝업 관리", list: "popups" },
                { menuId: 1, id:5,  name: "연혁 관리",    list: "histories" },
                { menuId: 1, id:6,  name: "논문현황 관리", list: "articels" },
                { menuId: 1, id:7,  name: "특허현황 관리", list: "patents" },
                { menuId: 1, id:8,  name: "기업뉴스 관리", list: "news" },
                { menuId: 1, id:9,  name: "공지사항 관리", list: "notices" },
                { menuId: 1, id:10, name: "채용공고 관리", list: "recruits" },
            ]
        }; 
        return menuList;
    },
    set: ({set}, value) => set(selectMenuState, value),
});

export const menuHideState = atom({
    key: 'menuHideState',
    default: false,
});

/* 
 * ==========================================================================
 */

//
export const bannersState = atom({
    key: 'bannersState',
    default: [],
});

//
export const loadingState = atom({
    key:'loadingState',
    default: true,
});

export const loadingStateSelect = selector( {
    key:'loadingStateSelect',
    get: ({get}) => {},
    set: ({set}, value) => set(loadingState, value),
})

export const language = atom({
    key:'language',
    default: window.localStorage.getItem('language'),
})