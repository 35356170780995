import React, { useEffect, useState  } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';

import { menuState, menuHideState } from '../../atom/state';
import { useRecoilState, useRecoilValue } from 'recoil';

const Menu = () => {
  
  const [menuOpencheck, setCheck] = useState(false);
  const [hideStatus, setHide] = useState(false);

  const menu = useRecoilValue(menuState);
  const hide = useRecoilValue(menuHideState);

  
  let menuArray = [];

  const menuToggle = (event) => {
    event.preventDefault();
    menuOpencheck ? setCheck(false) : setCheck(true)
  }

  useEffect(()=> {
    setHide(hide);
  }, [hide])

  menu.titleMenuList.map(i => {
    menuArray[i.id] = 
    <MenuBox key={i.id}>
      <MenuHeader 
        onClick={menuToggle}
      >
        {i.name}
        {/* <MenuArrow
          status={menuOpencheck}
        >
          ⌃
        </MenuArrow> */}
      </MenuHeader>

      {menu.subMenuList.map(j => {
        if(i.id == j.menuId) {
          // 하위 메뉴 클릭 시, 부모 컴포넌트로 클릭한 메뉴의 list value 를 보낸다.
          return (
            // 하기 태그 Link Component 사용
            <Menuwrap key={j.id} to="/list" state={{ type : j.id }}>
              <MenuLine />
              <MenuList selectcolor={false}>
                {j.name}
              </MenuList>
            </Menuwrap>
            
          )
        }
      })}
    </MenuBox>
  })
  // 

  return(
    <Container status={hideStatus}>
      {menuArray}
    </Container>
  )
}

export default Menu;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.status ? "0px;" : "200px")};
  min-width: 200px;
  box-shadow: 5px 0 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #f5f5f5;
  z-index: 10;
  overflow-y:scroll; 
`;

const MenuBox = styled.div`
  width: 100%;
  color: #fff;
  padding-bottom: 20px;
  background: white;
}
`;

const MenuHeader = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 56px;
  background-color: #f90;
  align-items: center;
  padding-left: 24px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;

const Menuwrap = styled(Link)`
  display:flex;
  flex-direction: row;
  background-color: white;
  text-decoration: none;
`;

const MenuList = styled.div`
  position: relative;
  top: 12px;
  left: 5px;
  display: flex;
  width: calc (100% - 32px) ;
  height: 25px;
  background-color: #fff;
  color: #605f5ff7;
  align-items: center;
  &:hover {
    color: #ff9900;
    text-decoration: underline;
  }
  font-size: 0.9em;
  text-align: left;
  color: ${(props) => (props.selectcolor ? 
                            "#ff9900; text-decoration: underline;"
                            : 
                            "#605f5ff7;"
             )};
`;

const MenuArrow = styled.span`
  position: absolute;
  left: 165px;
  width: 15px;
  height: 15px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  text-align: right;
  color: #fff;
  transition: all 0.2s linear;
  transform: ${(props) => (props.status ? "rotate(180deg)" : "rotate(0deg)")};
`;

const MenuLine = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 0 0 20px;
  border-left: dotted 0.5px #005ba1;
  border-bottom: dotted 0.5px #005ba1;
`;