import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Table from './components/Table';

import {list} from '../../../api/List';

import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingStateSelect } from '../../../atom/state';

const NoticeTable = () => {

  const [ tableData, setData ] = useState([]);
  const [ _loading, setLoading ] = useRecoilState(loadingStateSelect);

  useEffect(() => {
    let check = list('notices');
    check.then((res) => { 
      setData(res.data.data.notices);
      setLoading(false);
    });
  }, [])

  const dataColumns = [
    {  id: "번호",  style: { width: '100px', }, type: 'text' },
    {  status: "공지여부",  style: { width: '80px', }, type: 'type' },
    {  title: "제목",  style: { width: '40%', }, type: "text" },
    {  count: "조회수",  style: { width: '100px', }, type: "text"},
    {  created_at: "등록일", style: { 'width': '100px', }, type: "date" }
  ];

  // 데이터 열에 대한 스타일 정의
  const styleSet = {
    id: {'textAlign' : 'center' },
    status: {'textAlign' : 'center', },
    title: {'textAlign' : 'center', },
    count: {'textAlign' : 'center', },
    created_at: {'textAlign' : 'center' },
  }
  
  return(
    <Container>
        <Table
          columns={dataColumns}
          data={tableData}
          style={styleSet}
        />
    </Container>
  )
}

export default NoticeTable;


const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color:blue;
`;