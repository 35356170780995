import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {deleteData} from '../../../../api/List';

const InputButton = (props) => {

    const [getData, setData] = useState([]);

    useEffect(() => {
        setData(props.data);
    }, [])

    const onClickHandle = e => {
        // register, select drop process
        if(props.type == "register") {
            window.location.href="/detail";
        } else if (props.type == "select") {
            if(getData.size > 0){
                let cmd = localStorage.getItem('menu');
                let check = null;
                check = deleteData('removeList', getData, cmd);
                check.then((res) => { 
                    if(res.data.success) {
                        window.location.reload();
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                });
            } else {
                alert("선택된 문서가 없습니다.");
            }
        }
    }

    return(
        <InputBtn
            type ="button"
            value={props.name}
            status={props.type}
            onClick={onClickHandle}
        />
    )
}

export default InputButton;


const InputBtn = styled.input`
    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
    }
`;