import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Table from './components/Table';

import {list} from '../../../api/List';

import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingStateSelect } from '../../../atom/state';

const BannerTable = () => {

  const [ tableData, setData ] = useState([]);
  const [ _loading, setLoading ] = useRecoilState(loadingStateSelect);

  useEffect(() => {
    let check = list('banners');
    check.then((res) => { 
      setData(res.data.data.banners);
      setLoading(false);
    });
  }, [])

  const dataColumns = [
    {  id: "번호",  style: { width: '100px', }, type: 'text' },
    {  file_addr: "이미지",  style: { width: '100px', }, type: "img" },
    {  topTitle: "상단 타이틀", style: { 'width': '30%', }, type: 'text' },
    {  bottomTitle: "하단 타이틀", style: { 'width': '30%', }, type: 'text' },
    {  created_at: "등록일", style: { 'width': '150px', }, type: 'date' }
  ];

  // 데이터 열에 대한 스타일 정의
  const styleSet = {
    id: {'textAlign' : 'center' },
    file_addr: {'textAlign' : 'center', 'width' : '24px', 'height' : '24px' },
    topTitle: {'textAlign' : 'center', "whiteSpace": "pre-wrap"},
    bottomTitle: {'textAlign' : 'center', "whiteSpace": "pre-wrap" },
    created_at: {'textAlign' : 'center' },
  }
  
  return(
    <Container>
        <Table
          columns={dataColumns}
          data={tableData}
          style={styleSet}
        />
    </Container>
  )
}

export default BannerTable;


const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color:blue;
`;