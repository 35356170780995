import React from 'react';
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';

import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';

import './App.css';
import Login from './pages/Login';
import Detail from './pages/Detail';
import List from './pages/List';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/detail" element={<Detail />}></Route>
      <Route path="/list" element={<List />}></Route>
    </Routes>
  </BrowserRouter>
)

export default App;
