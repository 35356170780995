import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

function Loader({ type, color }) {
    return (
        <Container>
            <ReactLoading
                type={type}
                color={color}
                // height={'30%'}
                width={'32px'} />
        </Container>
    );
}

export default Loader;

const Container = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.99;
`;