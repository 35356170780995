import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import icon_file_remove from '../../../images/icon_file_list_remove.png';

import { recruitsRegister, recruitsUpdate} from '../../../api/List';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loadingStateSelect } from '../../../atom/state';
import { ko } from "date-fns/esm/locale";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from './components/Loading';
import { detail } from '../../../api/List';
import DragDrop from './components/DragDrop';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const RecruitsDetail = (props) => {

    let status = 1;

    const [ pageStatus,     setPageStatus ]  = useState(false);             // 등록/수정 상태 [ false: 등록, ]
    const [ ingCheck, setIngCheck ] = useState(true);
    const [ endCheck, setEndCheck ] = useState(false);

    const [ getRecruitsTitle, setRecruitsTitle ] = useState('');
    const [ getContent, setContent ] = useState('');
    const [ getFiles, setFiles ] = useState([]);
    const [ getOldFiles, setOldFiles ] = useState([]);
    const [ getRemoveFiles, setRemoveFiles ] = useState(new Set());
    const [ getSelectStartDate, setSelectStartDate ]  = useState(new Date());
    const [ getSelectEndDate, setSelectEndDate ]  = useState(new Date());
    const [ getFooters, setFooters ] = useState('');

    const [ tableData, setData ] = useState([]);
    const [ tableFile, setTableFiles ] = useState([]);

    const [ _loading, setLoading ] = useState(false);

    // ref
    const inputRef = React.useRef(null);

    const filesUploadHandle = (e) => { 

        let fileArray = [];
        let files = e.target.files;
        let fileLength = files.length;

        for (let i = 0; i < fileLength; i++) {
            fileArray.push(files[i]);
        }

        getFiles.map((item) => {
            fileArray.push(item);
        })

        setFiles(fileArray);
    }

    useEffect(() => {
        setLoading(true);

        if(props.data.length > 0) { setPageStatus(true); } 
        else if (Object.keys(props.data).length > 0) {
            setPageStatus(true);
        }

        if(Object.keys(props.data).length > 0) {
            if(tableData){
                if(tableData.length < 1) {
                    let check = detail('detail/recruits', props.data.id);
                    check.then((res) => { 
                        
                        setData(res.data.data);
                        if(res.data.data.recruits != null) {
                            if ( Object.keys(res.data.data.recruits).length > 0 ) {

                                if(res.data.data.recruits.status == 1) {
                                    setIngCheck(true);
                                } else if(res.data.data.recruits.status == 2){
                                    setIngCheck(false);
                                    setEndCheck(true);
                                }
                                
                                setRecruitsTitle(res.data.data.recruits.title);
                                setContent(res.data.data.recruits.content);
                                setFooters(res.data.data.footers.content);
                            }
                        }

                        if(res.data.data.files != null) {
                            if ( res.data.data.files != null || res.data.data.files.length > 0 ) {
                                let filesArray = res.data.data.files;
                                setOldFiles(filesArray);
                            }
                        }
                        
                    });  
                }
            }
        }
        setTimeout(()=>{ setLoading(false); }, 500);
    }, []); 

    var date = new Date();
    const formatDate = (date)=>{
        let formatted_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
        return formatted_date;
    }

    const registerHandle = (e) => {

        if(e.target.getAttribute("type") == "register") {
            
            if(!getRecruitsTitle || !getContent) {
                // console.log(getRecruitsTitle);
                // console.log(getContent);
                alert("제목 및 내용은 필수 일력입니다.");
                return;
            }

            if(ingCheck) {
                status = 1;
            } else {
                status = 2;
            }

            let data = {check: status, title : getRecruitsTitle, content : getContent, files : getFiles, start_date : formatDate(getSelectStartDate), end_date : formatDate(getSelectEndDate), footers: getFooters}        
            let check = recruitsRegister('register/recruits', data);
            check.then((res) => {
                if(res.data.success) {
                    window.location.href = "/list";
                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                }
            });

        } else {

            if(!getRecruitsTitle || !getContent) {
                alert("제목 및 내용은 필수 일력입니다.");
                return;
            }

            if(!getSelectStartDate || !getSelectEndDate) {
                alert("채용 시작 및 마감일자 선택 부탁드립니다.");
                return;
            }


            if(ingCheck) {
                status = 1;
            } else {
                status = 2;
            }
            
            let data = {id: props.data.id, check: status, title : getRecruitsTitle, content : getContent, files : getFiles, removeFiles: getRemoveFiles,  start_date : formatDate(getSelectStartDate), end_date : formatDate(getSelectEndDate), footers: getFooters }
            let check = recruitsUpdate('update/recruits', data);
            check.then((res) => {
                if(res.data.success) {
                    window.location.href = "/list";
                } else {
                    if(res.data.msg) {
                        alert(res.data.msg);
                    } else {
                        alert("일시적인 오류가 발생했습니다.");
                    }
                }
            });

        }
    }

    const ingCheckHandle = (e) => { 
        let check = e.target.checked;
        setIngCheck(check); 
        setEndCheck(check ? false : true);
        status = check ? true : false;
    };

    const endCheckHandle = (e) => { 
        let check = e.target.checked;
        setEndCheck(check); 
        setIngCheck(check ? false : true);
        status = check ? false : true;
    };

    const modules = {
        toolbar: [
          //[{ 'font': [] }],
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          [{ 'align': [] }, { 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          ['clean'],
          [{'handlers' : {'image': 'imageHandler' } }],
        ],
      }
    
    const formats = [
        'font',
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
        'align', 'color', 'background',        
    ];

    const handleText = (text) => {
        setContent(text);
    }

    const imageDeleteHandle = (e) => {
    
        let id = e.target.getAttribute('fileid');
        getRemoveFiles.add(id);

        let filesRefresh = [];

        getOldFiles.map((file) => {
            if(file.id != id) {
                filesRefresh.push(file);
            }
        });
        
        setOldFiles(filesRefresh);
    }

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    const addFileDeleteHandel = (lastModified) => {
        let tempFilesArray = [];
        getFiles.map(file => {
            if(file.lastModified != lastModified) {
                tempFilesArray.push(file);
            }
        });
        setFiles(tempFilesArray);
    }

    function leftPad(value) {
        if(value >= 10) {
            return value;
        }
        return `0${value}`;
    }

    const setSelectStartDateHandle = (date) => {
        const year = date.getFullYear();
        const month = leftPad(date.getMonth() + 1);
        const day = leftPad(date.getDate());

        if(getSelectEndDate < date) {
            alert("시작일이 종료일 이후일 수 없습니다.");
        } else {
            setSelectStartDate(new Date([year, month, day].join('-')));
        }
    }

    const setSelectEndDateHandle = (date) => {

        const year = date.getFullYear();
        const month = leftPad(date.getMonth() + 1);
        const day = leftPad(date.getDate());


        if(getSelectStartDate > date) {
            alert("종료일이 시작일 이전일 수 없습니다.");
        } else {
            setSelectEndDate(new Date([year, month, day].join('-')));
        }
    }

    const recruitsTitleHandle = (e) => {
        setRecruitsTitle(e.target.value);
    }

    let loadingComponent; 
    if(_loading) {
        loadingComponent = <Loader type="spin" color="#f90" />;
      }
    
    return (
        <Container>
            {loadingComponent}
            <Content>
                <Header>
                    <h2>채용공고 관리</h2>
                </Header>
                <ContentBox>
                    <ContentHeader>
                        <h2>채용공고 { pageStatus ? "수정" : "등록" }</h2>
                    </ContentHeader>
                    <ContentTable>
                        <RowTable>
                            {/* 상태, 공고제목, 시작일, 마감일, 내용, 유의사항, 첨부파일(multi) */}
                            <Row>
                                <LabelSection>상태</LabelSection>
                                <CheckBoxArea>
                                    <div>
                                        <CheckboxStyle
                                            onChange={ingCheckHandle}
                                            checked={ingCheck}
                                        />
                                        <span>채용중</span>
                                    </div>
                                    { Object.keys(props.data).length > 0 ?
                                        <div>
                                            <CheckboxStyle
                                                onChange={endCheckHandle}
                                                checked={endCheck}
                                            />
                                            <span>마감</span>
                                        </div>
                                        :
                                        ''
                                    }
                                </CheckBoxArea>
                            </Row>

                            <Row>
                                <LabelSection>공고제목</LabelSection>
                                <InputText 
                                    placeholder='제목을 입력하세요.'
                                    onChange={recruitsTitleHandle}
                                    defaultValue={getRecruitsTitle}
                                />
                            </Row>

                            <Row>
                                <LabelSection>시작일</LabelSection>

                                <DatePickerBox>
                                    <DatePickerReStyle
                                            locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                            selected={getSelectStartDate}
                                            placeholderText="발행날짜를 선택하세요."
                                            closeOnScroll={true}
                                            onChange={(date) => setSelectStartDateHandle(date)}
                                        >    
                                    </DatePickerReStyle>
                                </DatePickerBox>
                            
                                <LabelSection>종료일</LabelSection>

                                <DatePickerBox>
                                    <DatePickerReStyle
                                            locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                            selected={getSelectEndDate}
                                            placeholderText="발행날짜를 선택하세요."
                                            closeOnScroll={true}
                                            onChange={(date) => setSelectEndDateHandle(date)}
                                        >    
                                    </DatePickerReStyle> 
                                </DatePickerBox>
                            </Row>

                            <Row>
                                <LabelSection>내용</LabelSection>
                                <EditorBox>
                                <ReactQuillStyle 
                                    style={{height: "600px"}} 
                                    theme="snow" 
                                    modules={modules} 
                                    formats={formats} 
                                    value={getContent}
                                    onChange={handleText} />
                                </EditorBox>
                            </Row>

                            <Row>
                                <LabelSection>유의사항<br/>(전체적용)</LabelSection>
                                <TextArea 
                                    onChange={(e) => setFooters(e.target.value)}
                                    defaultValue={getFooters}
                                />
                            </Row>

                            <Row>
                                <LabelSection>첨부파일</LabelSection>
                                <FileUpload>

                                    <div id="uploadFileList">
                                    
                                        {(getOldFiles.length < 1) && (getFiles.length < 1)  ? 
                                            <div id="blankFileList"><p>하단의 버튼을 눌러 <span>파일첨부</span> 해주세요.</p></div>
                                            :
                                            ''
                                        }

                                        {getFiles.map((file, index) => {
                                            return <div id="file" key={index}>
                                                        <span>{file.name}</span>
                                                        <img onClick={() => addFileDeleteHandel(file.lastModified)} src={icon_file_remove} />
                                                   </div>
                                        })}
                                        
                                        {getOldFiles.map(file => {
                                            
                                            return <div id="file" key={file.id} >
                                                        <span>{file.file_name}</span>
                                                        <img fileid={file.id} onClick={imageDeleteHandle} src={icon_file_remove} />
                                                   </div>
                                        })}
                                        
                                    </div>

                                    <div id="uploadBox">
                                        <label id="label-file-upload" htmlFor='input-file-upload'>
                                            <input ref={inputRef} id='input-file-upload' type='file' multiple={true} onChange={filesUploadHandle}/>
                                            <button className="upload-button" onClick={onButtonClick}>파일첨부 (Click)</button>
                                        </label>
                                    </div>
                                </FileUpload>
                            </Row>
                        </RowTable>

                    </ContentTable>
                    <ContentButton>
                    <ContentButtonCenter>
                        <LinkStyle to="/list">목록</LinkStyle>

                        <ControllerButton 
                            status="register"
                            type={pageStatus ? "update" : "register"}
                            onClick={registerHandle}
                        >
                            <span>{pageStatus ? "저장" : "등록"}</span>
                        </ControllerButton>
                    </ContentButtonCenter>
                    </ContentButton>
                </ContentBox>
            </Content>
        </Container>
    )
}

export default RecruitsDetail;

const Container = styled.div`
    width: calc(100% - 200px);
    min-width: 768px;
    max-width: 1200px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
`;

const Content = styled.div`
    width: calc(100% - 100px);
`;

const Header = styled.div`
    width: 100%;
    height: 60px;
    
`;

const ContentBox = styled.div`
    width: 100%;
    min-width: 720px;
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
`;

// ContentBox > ContentHeader
const ContentHeader = styled.div`
    width: 100%;
    height: 60px;
    padding: 20px;
    h2 {
        color: #ff9900;
        font-weight: bold;
    }
`;

// ContentBox > ContentTable
const ContentTable = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: row;
    padding: 30px;
`;

const RowTable = styled.div`
    width: 100%; 
    display: flex;
    flex-direction: column;
    border: 1px solid #d5d5d5;
    border-top: 3px solid #444;
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #d5d5d5; 
`;

const TopTitle = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
`;

const BottomTitle = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
`;

const ImageSelect = styled.div`
    widht: 100%;
    height: 30%;
    border: 1px solid #d5d5d5;
    border-top: none;
    display:flex;
`;

const ContentButton = styled.div`
    width: 100%;
    height: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
`;

const ContentButtonCenter = styled.div`
    display: flex;
`;

const LabelSection = styled.div`
    width: 200px;
    height: 100%;
    background-color: #f5f5f5;
    padding: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    border-right: 1px solid #d5d5d5;
`;

const TextArea = styled.textarea`
    width: calc(100% - 200px);
    height: 200px;
    border: none;
    resize: none;
    outline: none;
    padding: 20px;
`;

const FileSection = styled.div`
    width: calc(100% - 200px);
    display:flex;
    padding-left:20px;
    align-items: center;
`;

const InputFile = styled.input`
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
`;

const InputText = styled.input`
    width: calc(100% - 200px);
    padding: 10px;
    border: 0;
    outline: none;
    padding-left: 20px;
`;

const ControllerButton = styled.div`
    display:flex;
    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
    }

    justify-content: center;
    align-items: center;

`;

const LinkStyle = styled(Link)`
    display:flex;
    width: ${(props) => (props.status == "register" ? "120px" : "92px")};
    height: 42px;
    margin: 10px;
    margin-left: 10px;
    margin-right: 0px;
    border: solid 1px #f90;
    background-color: ${(props) => (props.status == "register" ? "#f90" : "#e6e6e6")};
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border: solid 1px ${(props) => (props.status == "register" ? "#f90;" : "#d8d8d8")};
    border-radius: 2px;
    &:hover {
        cursor: pointer;
        color:#fff;
    }

    justify-content: center;
    align-items: center;
`

const OldImageFile = styled.div`
    width: 100%
    height: 56px;
    padding: 5px;
    background-color: #f5f5f5;
    font-weight: bold;
`;

const LabelStyle = styled.label`
    font-size: 12px;
    font-weight: 500;
    color: #8d8d8d
    width: calc(100%-120px);
    &:hover {
        cursor: pointer;
    }
`;

const UploadFileButton = styled.div`
    width: 120px;
    height: 40px;
    border: solid 1px #f90;
    font-size: 1rem;
    font-weight: bold;
    color: #f90;
    text-align: center;
    margin: 20px;
    padding-top: 7px; 
    display: inline-block;
`;

const EditorBox = styled.div`
    width: calc(100% - 200px);
    height: 650px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 70px;
`;

const ReactQuillStyle = styled(ReactQuill)`
    width: 100%;
    height: 100%;
    margin: 0;
`;

const CheckboxStyle = styled(Checkbox)`
    // position: relative;
    // left: 20px;
    // top: 20px;
    margin-left: 20px;
    margin-right: 5px;
`;

const DragBox = styled.div`
    width: calc(100% - 200px);
    height: 300px;
    padding: 20px;
    overflow: scroll;
`;

const CheckBoxArea = styled.div`
    width: calc(100% - 200px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
`;  

const FileUpload = styled.div`
    width: calc(100% - 200px);
    height: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    #uploadFileList {
        width: 100%;
        height: 230px;
        background-color: #f5f5f5;
        overflow: hidden;
        overflow-y: scroll;

        #blankFileList {
            width: 100%;
            height: 100%;
            border: solid 0.6px #aaa;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            color: #bbb;

            p {
                span {
                    color: #f90;
                }
            }
        }

        #file {
            width: 100%;
            height: 56px;
            border: solid 0.6px #aaa;
            background-color: #fff;
            display: flex;
            align-items: center;
            padding-left: 20px;
            
            font-size: 16px;
            font-weight: 300;
            color: #454545;
            margin-bottom: 3px;

            img {
                width: 20px;
                height: 20px;
                margin-left: 10px;
            }
        }
    }

    #uploadBox {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        #label-file-upload {
            display: flex;
            width: 100%;
            height: 100%;

            #input-file-upload {
                display: none;
            }

            button { 
                width: 100%;
                border: solid 0.6px #aaa;
                background-color: #fff;
                font-weight: 500;
                color: #454545;
            }
        }

    }

`;

const DatePickerReStyle = styled(DatePicker)`
    width: 90%;
    max-width: 150px;
    height: 30px;
    text-align: center;
    border: solid 1px #f90;
    outline: none;
    position: relative;
    font-size: 14px;
    font-weight: 700;
    background-color: #f90;
    color: #ffffff;
    border-radius: 3px;
`

const DatePickerBox = styled.div`
    width: calc(50% - 200px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
`;